import React, { useState } from "react";
import { Select, MenuItem, Grid, CardContent } from "@mui/material";

import "../../assets/css/cms.css";
import "antd/dist/reset.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Watermark ,Spin } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import parse from 'html-react-parser';
import DOMPurify from "dompurify";
import ReactQuill from "react-quill";
import MedicalPreConfigViewer from "../MedicalPreConfigViewer";

const AIResult = ({ chatMessages ,aiQueryData,chatMessagesResponse,flagAIQueried}) => {


console.log("chatMessages:",chatMessages);
console.log("aiQueryData",aiQueryData);
console.log("chatMessagesResponse",chatMessagesResponse);
console.log("flagAIQueried",flagAIQueried);

const getPersianTranslation = (content) => {
  const startIndex = content.indexOf("Translation to Persian (Farsi):") ;
  return content.slice(startIndex).trim();
};
const extractPersianTranslation = (content) => {
  // Your logic to identify and extract the Persian translation
  // Example: using regex to match Persian characters
  const persianRegex = /[\u0600-\u06FF\s]+/g;
  const matches = content.match(persianRegex);
  return matches ? matches.join(" ").trim() : "Translation not found";
};
const parseContent = (content) => {
  // Your custom parsing logic goes here
  // This is a simple example, you might need to adjust it based on your HTML structure
  const paragraphs = content.split("\n\n");
  return paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>);
};



return (

   <>
        <MedicalPreConfigViewer  aiQueryData={aiQueryData}/>

   <div dir="ltr" >
 
  <div className="container">
 <Card  type="inner"  title={!chatMessagesResponse?"Report Result":""}     className="custom-card"
          style={{float:"right", minHeight:"70px", width: "80%",justifyContent: "center", backgroundColor: "#f0f0f0" }}  >
   <Spin  spinning={flagAIQueried} tip={flagAIQueried?"Currently receiving information. ....":""}> 
      {chatMessages && (
        <>
        <Watermark content="AITabib">
    
 
          {chatMessages.map((data, index) => (
            <div key={index}>
              {data.role === "system" && <strong>{data.content}</strong>}
              {data.role === "user" && <span>User: {data.content}</span>}
              {data.role === "assistant" && (
                <span>{parseContent(data.content)}</span>
              )}
              {/* Add more conditions for different roles if needed */}
            </div>
          ))}
           </Watermark>
        </>
      )}
      
    </Spin> 
    </Card>
 
    </div>
    
    </div>
    </>
);
};




export default AIResult;
