import React, { useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  CardContent,
} from "@mui/material";

import "../../assets/css/cms.css";
import "antd/dist/reset.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Button, Form, Input, Space, Row, Select,Checkbox, Col } from "antd";

import { CheckOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
const { Option } = Select;

const PatientPerson = ({ AIQueryData, handleChange }) => {
  console.log(AIQueryData);

  const [errorMessage, setErrorMessage] = useState("");
  const [ageValidationStatus, setAgeValidationStatus] = useState("");

  const handleInnerStepsChange = (values) => {
    console.log(errorMessage);

    // console.log(values.target.name);
    // console.log(values.target.value);
    // console.log(values.target.value.length);
    // console.log(values.target.name=="age");
    // console.log(values.target.value.length<=3);
    // console.log(/^\d*$/.test(values.target.value));
    // console.log(/^\d*$/.test(values.target.value) && values.target.value.length <= 3);
    //handleChange(values.target.name, values.target.value);

    if (values.target.name == "name") {
      if (values.target.value.length <= 20) {
        handleChange(values.target.name, values.target.value);
      } else {
      }
    } else if (values.target.name == "age") {
      if (
        /^\d*$/.test(values.target.value) &&
        values.target.value.length <= 3
      ) {
        setErrorMessage("");
        handleChange(values.target.name, values.target.value);
        setAgeValidationStatus("");
      } else {
        setErrorMessage("Ege must be 2 digit count");
        setAgeValidationStatus("error");
      }
    }
  };
  const onCheckBoxChange = (checkedValues) => {
    handleChange('test_type_checked', checkedValues);

    console.log('test_type_checked', checkedValues);
  };

  const validateInput = (_, value) => {
    // Check if the input is a number and has a length of 5
    if (/^\d*$/.test(value) && value.length <= 3) {
      setErrorMessage("");
      return Promise.resolve();
    } else {
      setErrorMessage("Ege must be 2 digit count");
      return Promise.reject();
    }
  };
  const handleInnerStepsSelectChange = (value, name) => {
    console.log(value);
    console.log(name);

    handleChange(name, value);
  };

  return (
    <div dir="ltr">
      <div className="container">
        <Card
          type="inner"
          title=" Your Personal Info:"
          className="custom-card"
          style={{
            width: "80%",
            justifyContent: "center",
            backgroundColor: "#f0f0f0",
          }}
        >
          <CardContent>
            {/* <Form> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={4}>
                <Input
                  autoComplete="off"
                  maxLength={20}
                  // showCount
                  required
                  name="name"
                  inputMode="text"
                  value={AIQueryData.name}
                  placeholder=" Name & Family Name "
                  onChange={handleInnerStepsChange}
                  // status="error"
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Select
                      style={{ width: "100%" }}
                      defaultValue={0}
                      placeholder=" Gender:"
                      className="myTextfield"
                      name="gender"
                      value={AIQueryData.gender}
                      onChange={(value) =>
                        handleInnerStepsSelectChange(value, "gender")
                      }
                    >
                      <Option key="0" value={0}>
                        Female
                      </Option>
                      <Option key="1" value={1}>
                        Male
                      </Option>
                    </Select>
                    <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                  </div>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={2}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    maxLength={3}
                    // showCount
                    autoComplete="off"
                    required
                    name="age"
                    placeholder="Age-Year"
                    onChange={handleInnerStepsChange}
                    value={AIQueryData.age}
                    status={ageValidationStatus}
                  />
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={2}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    maxCount={1}
                    className="myTextfield"
                    dir="ltr"
                    placeholder=" lang:"
                    name="lang"
                    value={
                      AIQueryData.lang != "" ? AIQueryData.lang : undefined
                    }
                    style={{ width: "100%" }}
                    mode="multiple"
                    optionLabelProp="Label"
                    onChange={(value) =>
                      handleInnerStepsSelectChange(value, "lang")
                    }
                    addonAfter={<span style={{ color: "red" }}>*</span>}
                  >
                    <Option key="Persian(Farsi)" value={"Persian"}>
                      Persian
                    </Option>
                    <Option key="Turkieh" value={"Turkieh"}>
                      Turkieh
                    </Option>
                    <Option key="German" value={"German"}>
                      Germany
                    </Option>
                    <Option key="English" value={"English"}>
                      English
                    </Option>
                    <Option key="Spanish" value={"Spanish"}>
                      Spanish
                    </Option>
                    <Option key="French" value={"French"}>
                      French
                    </Option>
                  </Select>
                  <span style={{ marginLeft: "5px", color: "red" }}>*</span>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={2}>
                <Checkbox.Group style={{float:"left", width: "100%" }} onChange={onCheckBoxChange} value={AIQueryData.Test_Types}>
                  <Row>
                    <Col span={24}>
                      <Checkbox  value="A">Drug interaction</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="B">Medical Tests</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Grid>
              <Grid item xs={12} sm={4}>
                {errorMessage}
              </Grid>
            </Grid>
            {/* </Form> */}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
export default PatientPerson;
