import React, { useState, useEffect } from "react";
import { Button, Steps, theme, Modal } from "antd";
import PatientPerson from "./MedicalCriteriaSteps/PatientPerson";
import CurrentDrugs from "./MedicalCriteriaSteps/CurrentDrugs";
import AIResult from "./MedicalCriteriaSteps/AIResult";
import TermsOfUsed from "./MedicalCriteriaSteps/TermsOfUsed";
import MedicalTests2 from "./MedicalCriteriaSteps/MedicalTests";

import {GenderDetection_En} from "../utils/bases/functions"
import { toast } from "react-toastify";
import axios from "axios";
import MedicalPreConfigViewer from "./MedicalPreConfigViewer";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../config/index";
import "../assets/css/cms.css";
import { colors } from '@mui/material';

const MedicalCriteriaConfig = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
// const [AIQueryDataResult, setAIQueryDataResult] = useState();

console.log("current",current);

  const navigate = useNavigate();

  const next = () => {
    console.log(current);
    console.log(AIQueryData.age);
    console.log(AIQueryData.gender);
    console.log(AIQueryData.age!='');
    console.log(AIQueryData.gender!='');
    console.log(AIQueryData.drugs.length);
    console.log(AIQueryData.Test_Types.length);
    console.log(AIQueryData.Test_Types);

    if(current==0)
    {
      setCurrent(current + 1);
      //navigate('/SignIn');

    }else
    if(current==1)
    { 
       if(AIQueryData.age!=='' && AIQueryData.gender!==''  && AIQueryData.lang!=='' )
       {
        if(AIQueryData.Test_Types.length>=1)
        {

           if(AIQueryData.Test_Types.length==1 && AIQueryData.Test_Types.includes('A'))
           {
               console.log("true"); 
               setCurrent(current + 1);
           }
           else
           if(AIQueryData.Test_Types.length==1 && AIQueryData.Test_Types.includes('B'))
           {
               console.log("true"); 
               setCurrent(current + 2);
           }
           else
           if(AIQueryData.Test_Types.length==2 && AIQueryData.Test_Types.includes('A') && AIQueryData.Test_Types.includes('B'))
          {
            console.log("true"); 
            setCurrent(current + 1);
          }
        
      }
        else
        {
          Modal.warning({
            title: "attention",
            content: "you must ckeck at last one test..  ",
            okText: "Ok ",
          });
         }
       }
       else
       {
        Modal.warning({
          title: "attention",
          content: "you must fill the * based fields ",
          okText: "Ok ",
        });
       }
    }else 
    if(current==2)
    {
       if(AIQueryData.drugs.length>=1 & AIQueryData.drugs[0].drug!='')
       {

        if(AIQueryData.Test_Types.length==1 && AIQueryData.Test_Types.includes('B'))
        {
            console.log("true"); 
            setCurrent(current + 1);
        }
        if(AIQueryData.Test_Types.length==1 && AIQueryData.Test_Types.includes('A'))
        {
           setCurrent(current + 2);
        }
        
        if(AIQueryData.Test_Types.length==2 && AIQueryData.Test_Types.includes('A') && AIQueryData.Test_Types.includes('B'))
       {
         console.log("true"); 
         setCurrent(current + 1);
       }
       }
       else
       {
        Modal.warning({
          title: "attention",
          content: "please enter your drugs for interaction check..",
          okText: "Ok ",
        });
       }
    }else 
    if(current==3)
    {
      
      setCurrent(current + 1);
    }

  };

  const prev = () => {

    if(current==2)
    { 
      
       
      setCurrent(current - 1);
     
      
    }else 
    if(current==3)
    {
      

      if(AIQueryData.Test_Types.length==1 && AIQueryData.Test_Types.includes('A'))
      {
          console.log("true"); 
          setCurrent(current - 1);
      }
      else
      if(AIQueryData.Test_Types.length==1 && AIQueryData.Test_Types.includes('B'))
      {
          console.log("true"); 
          setCurrent(current - 2);
      }
      else
      if(AIQueryData.Test_Types.length==2 && AIQueryData.Test_Types.includes('A') && AIQueryData.Test_Types.includes('B'))
     {
       console.log("true"); 
       setCurrent(current - 1);
     }
      
    }
    if(current==4)
    {
      

      if(AIQueryData.Test_Types.length==1 && AIQueryData.Test_Types.includes('A'))
      {
          console.log("true"); 
          setCurrent(current - 2);
      }
      else
      if(AIQueryData.Test_Types.length==1 && AIQueryData.Test_Types.includes('B'))
      {
          console.log("true"); 
          setCurrent(current - 1);
      }
      else
      if(AIQueryData.Test_Types.length==2 && AIQueryData.Test_Types.includes('A') && AIQueryData.Test_Types.includes('B'))
     {
       console.log("true"); 
       setCurrent(current - 1);
     }
      
    }


   // setCurrent(current - 1);


  };

  //handlesubmit-----------------------------------------------------

  const [AIQueryData, setAIQueryData] = useState({
    id: 0,
    name: "",
    age:"",
    historicalPatient:"",
    gender:"",
    lang:"English",
    Test_Types:[],
    pdf_tests:"",
    drugs: [
      {
        drug: "",
        drugdoze: "",
        TimeDuration: "",
        count: "",
        
      },
    ],
  });
  const [emptySCCDataLength, setEmptySCCDataLength] = useState(
    JSON.stringify([AIQueryData]).length
  );
  const [filledSCCDataLength, setFilledSCCDataLength] = useState(0);



const [chatMessages, setChatMessages] = useState([]);
const [chatMessagesResponse, setchatMessagesResponse] = useState(true);
const [flagAIQueried, setFlagAIQueried] = useState(false);
const [flagTermsOfUsed, setflagTermsOfUsed] = useState(false);

console.log(AIQueryData);

        const requestDataLVLDrugs = {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: `You are a medical assistant providing information about drugs interactions, then your prepared chat as translator must be translate to ${AIQueryData.lang} without original english text`,
            },
            {
              role: "user",
              content:
                `Generate information in English about [Assume that this patient name is ${
                  AIQueryData.name
                } with age ${AIQueryData.age} and gender ${GenderDetection_En(
                  AIQueryData.gender
                )}.As medical drugs interaction analyzer Evaluate the interaction and side effects of the following drugs:` +
                AIQueryData.drugs
                  .map(
                    (dr, index) =>
                      `${dr.drug} with dosage ${dr.drugdoze} when taken for ${dr.TimeDuration} hours ${dr.count} time(s) a day &`
                  )
                  .join("") +
                `translate to ${AIQueryData.lang}`,
            },
          ],
          temperature: 0.0,
          max_tokens: 2024,
          stop: "translate to persian",
          // target_language:"fa"
        };
        const requestDataLVLMedicaltests = {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: `You are a medical assistant providing information about medical tests analyzer,Please write me what specialist doctors need to refer and shows important parameter that has fault result, at last  translate the result to ${AIQueryData.lang} without original english text`,
            },
            {
              role: "user",
              content:`Generate information in English about [Assume that this patient name is ${
                AIQueryData.name
              } with age ${AIQueryData.age} and gender ${GenderDetection_En(
                AIQueryData.gender
              )},suppose these data as medical tests results ${AIQueryData.pdf_tests} and at the end  translate to ${AIQueryData.lang} without original english text `          },
          ],
          temperature: 0.0,
          max_tokens: 2024,
          stop: `translate to ${AIQueryData.lang}`,
          // target_language:"fa"
        };
        const requestDataLVLDrugsAndMedicaltests = {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: `You are a medical assistant providing information about drugs interactions and a medical tests analyzer,Please write me what specialist doctors need to refer and shows important parameter that has fault result, and analyzed Drugs for interaction and side effect of my drugs and my medical tests ,at last  translate the result to ${AIQueryData.lang} without original english text`,
            },
            {
              role: "user",
              content:
                `Generate information in English about [Assume that this patient name is ${
                  AIQueryData.name
                } with age ${AIQueryData.age} and gender ${GenderDetection_En(
                  AIQueryData.gender
                )}.As medical drugs interaction analyzer Evaluate the interaction and side effects of the following drugs:` +
                AIQueryData.drugs
                  .map(
                    (dr, index) =>
                      `${dr.drug} with dosage ${dr.drugdoze} when taken for ${dr.TimeDuration} hours ${dr.count} time(s) a day &`
                  )
                   .join("") +
                 `and suppose these data as medical tests ${AIQueryData.pdf_tests}  translate to ${AIQueryData.lang}`,
            },
          ],
          temperature: 0.0,
          max_tokens: 2024,
          stop: `translate to ${AIQueryData.lang}`,
          // target_language:"fa"
        };

  const handleSubmit = async () => {
    {
      try {
        console.log(requestDataLVLDrugsAndMedicaltests);

        var query="";
        if(AIQueryData.Test_Types.length==1 && AIQueryData.Test_Types.includes('A'))
           {
            query=requestDataLVLDrugs;
           }
           else
           if(AIQueryData.Test_Types.length==1 && AIQueryData.Test_Types.includes('B'))
           {
            query=requestDataLVLMedicaltests;
           }
           else
           if(AIQueryData.Test_Types.length==2 && AIQueryData.Test_Types.includes('A') && AIQueryData.Test_Types.includes('B'))
          {
            query=requestDataLVLDrugsAndMedicaltests;
          }
        //setAIQueryDataResult(null);
        // Make your OpenAI API call here and get the response
        const response = await fetch(
          "https://api.openai.com/v1/chat/completions",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer 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",
            },
            body: JSON.stringify(query),
          }
        );

        const data = await response.json();
        console.log(data);
        if(data){
        // Process the data and update chatMessages
        setChatMessages(data.choices.map((choice) => choice.message));
        setchatMessagesResponse(false);
        setFlagAIQueried(false);
        console.log(data.choices.map((choice) => choice.message));
        }
        console.log(data.choices.map((choice) => choice.message));

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Set loading to false once the data is fetched
      }
    }
  };


  //end handlesubmit-------------------------------------------------
  // const contentStyle: React.CSSProperties = {
  //   lineHeight: "16px",
  //   color: token.colorTextTertiary,
  //   borderRadius: token.borderRadiusLG,
  //   border: `1px dashed ${token.colorBorder}`,
  //   marginTop: 0,
  //   width: "100%",
  //   justifyContent: "center",
  // };
  //------------------------------------------------------------------------

  useEffect(() => {
    if(chatMessagesResponse & flagAIQueried)
    {
      setFlagAIQueried(false);
    console.log(flagAIQueried);
    }
    }, []);

  const handleTermsOfUsedChange = (name, event) => {
   
    if (name==="termsofused")
    {
     setflagTermsOfUsed(event);
     if (event) {
      console.log(event);
      //window.scrollTo(0, document.body.scrollHeight);

    }
    }
   console.log(event);
  }

  const handleChange = (name, event) => {
    console.log(event);
  if(name==="test_type_checked")
  {
    console.log(AIQueryData);

    setAIQueryData((prevState) => {
      const mcc = { ...prevState };
      console.log([event]);

      mcc.Test_Types =  Array.isArray(event) ? event : [];
      return mcc;
    });
  }
    if(name ==="pdf_tests")
    {
      console.log(name);
      console.log(event);

      setAIQueryData((prevState) => {
         const mcc = { ...prevState };
         mcc.pdf_tests = event;
        return mcc;
      });
    }
    if (name === "drugs") 
    {
      console.log(AIQueryData);

      setAIQueryData((prevState) => {
        const mcc = { ...prevState };
        console.log([event]);

        mcc.drugs =  Array.isArray(event) ? event : [];
        return mcc;
      });
    }
     if (name === "gender") {
      console.log(name);

      setAIQueryData((prevState) => {
         const mcc = { ...prevState };
         mcc.gender = event;
        return mcc;
      });
    }
    if (name === "age") {
      console.log(name);

      setAIQueryData((prevState) => {
         const mcc = { ...prevState };
         mcc.age = event;
        return mcc;
      });
    }
    if (name === "name") {
      console.log(name);
      setAIQueryData((prevState) => {
         const mcc = { ...prevState };
         mcc.name = event;
        return mcc;
      });
    }
    if (name === "drugdoze") {
      console.log(name);
      setAIQueryData((prevState) => {
         const mcc = { ...prevState };
         mcc.drugdoze = event;
        return mcc;
      });
    }
    if (name === "lang") {
      console.log(name);
      setAIQueryData((prevState) => {
         const mcc = { ...prevState };
         mcc.lang = event[0];//because multimode
        return mcc;
      });
    }
    
  }

  //end-handels-------------------------------------------------------------

  //declare Steps inside block----------------------------------------------
  const steps = [
    {
      
      title: "TermOfUsed",
      content: <TermsOfUsed  handleTermsOfUsedChange={handleTermsOfUsedChange}/>,
    },
    {
      title: "Fisrt Stage",
      content: <PatientPerson AIQueryData={AIQueryData} handleChange={handleChange} />,
    },
    {
      title: "2nd Stage",
      content: <CurrentDrugs AIQueryData={AIQueryData} handleChange={handleChange} />,
    },
    {
      title: "3nd Stage(skippable)",
      content: <MedicalTests2  AIQueryData={AIQueryData} handleChange={handleChange}/>,
    },
    {
      title: "Result Stage",
      content: <AIResult  chatMessages={chatMessages} aiQueryData={[AIQueryData]} chatMessagesResponse={chatMessagesResponse} flagAIQueried={flagAIQueried}/>,
    },
    
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  // declare Steps inside block----------------------------------------------
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Please Be The Patient For The Result....");

  const showModal = () => {
    setOpen(true);
      
  };

  const handleOk = () => {
    //setModalText('The modal will be closed after two seconds');
    setFlagAIQueried(true);
    handleSubmit();
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 4000);
   
  };

  const handleCancel = () => {
    ////console.log("Clicked cancel button");
    setOpen(false);
  };
  const CancelForm = () => {
    navigate("/Donation");
  };

  return (
    <div dir="ltr">
      <div className="container">
        <Steps
          style={{ width: "55%", marginTop: "20px" ,marginBottom:"0px"}}
          percent={100}
          size="small"
          direction="horizontal"
          current={current}
          items={items}
        />
        {/* <div>{renderSteps()}</div> */}
      </div>
      <div className="contentStyle">{steps[current].content}</div>
      <div style={{display:"flex", justifyContent:"center"}}>
        {/* <div style={{ position: 'fixed', top: '200px', width: '100%', justifyContent:"center",  marginTop: 24 }}> */}
        {flagTermsOfUsed &&(
        <div> 
    
          {current < steps.length - 1 && (
            <Button
              style={{ marginBottom: "60px", margin: "0 8px" }}
              type="primary"
              onClick={() => next()}
            >
            Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button disabled={!chatMessagesResponse} type="primary" onClick={showModal}>
              Result           </Button>
          )}
          {current > 1 && (
            <Button
              style={{ marginBottom: "60px", margin: "0 8px" }}
              onClick={() => prev()}
            >
           Previous             </Button>
          )}
          <Button
            type="primary"
            style={{ marginBottom: "60px", margin: "0 8px" }}
            onClick={CancelForm}
          >
            Reject
          </Button>
          </div>
        )}
      </div>
      <div>
        
      </div>
      <Modal
        width="800px"
        title="Preview"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Apply"
        cancelText="close"
      >
        <p>{modalText}</p>
        <span>
        In accordance with the model rules, your information will not be used in any way.        </span>
        {/* <MedicalPreConfigViewer aiQueryData={[AIQueryData]} /> */}
      </Modal>
    </div>
  );
};

export default MedicalCriteriaConfig;
