



export const  PNCValid=(MeliCode)=> {
    let Result = false;
    if (MeliCode.length === 10) {
      if (MeliCode[0] === "0" && MeliCode[1] === "0" && MeliCode[2] === "0" && MeliCode[3] === "0" && MeliCode[4] === "0" && MeliCode[5] === "0" && MeliCode[6] === "0") {
        Result = true;
      } else {
        let c = parseInt(MeliCode[9]);
        let n = (parseInt(MeliCode[0]) * 10) + (parseInt(MeliCode[1]) * 9) + (parseInt(MeliCode[2]) * 8) + (parseInt(MeliCode[3]) * 7) + (parseInt(MeliCode[4]) * 6) + (parseInt(MeliCode[5]) * 5) + (parseInt(MeliCode[6]) * 4) + (parseInt(MeliCode[7]) * 3) + (parseInt(MeliCode[8]) * 2);
        let r = n - Math.floor(n / 11) * 11;
        if ((r === 0 && r === c) || (r === 1 && c === 1) || (r > 1 && c === 11 - r)) {
          Result = true;
        } else {
          Result = false;
        }
      }
    } else {
      Result = false;
    }
    return Result;
  }

export const  GenderDetection_En=(key)=> {
    let Result = 'Male';
    
    if (key === 0) {
     Result='Female'
    } else {
      Result = 'Male';
    }
    return Result;
  }
  export const  GenderDetection_fa=(key)=> {
    let Result = 'مونث';
    
    if (key === 0) {
     Result='مونث'
    } else {
      Result = 'مذکر';
    }
    return Result;
  }

  