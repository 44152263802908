import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

const CLIENT_ID = '542338692274-9en8i25bfut1fpjriauajql18ggtlfjc.apps.googleusercontent.com';

const GoogleOAuth = () => {
  const navigate = useNavigate();

  const handleSuccess = (response) => {
    console.log('Login Successful: ', response.profileObj);
    // Handle token and redirect
  };

  const handleFailure = (response) => {
    console.log('Login Failed: ', response);
  };

  return (
    <div  dir="rtl">
    <div className="container">
      
    <div>
      <h1>Login</h1>
      <h5>Welcome back! Let's take you to your account.</h5>

      <GoogleOAuthProvider clientId={CLIENT_ID}>
    <GoogleLogin
      onSuccess={(response) => {
        console.log(response);
        //navigate("/OauthCallback", { state: { data: response.credential }, });
        navigate("/");
      }}
      onError={() => {
        console.log('Login Failed');
      }}
    />
  </GoogleOAuthProvider>
    </div>
      
    </div>
  </div>


   
  );
};

export default GoogleOAuth;
