import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import "../../assets/css/cms.css";
import "antd/dist/reset.css";
import { MinusCircleOutlined, PlusOutlined,MinusOutlined ,ClockCircleOutlined} from "@ant-design/icons";
import {Card,Button, Form, Input, Space, Row, Select, AutoComplete} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { getFDADrugs } from "../../utils/bases/FDAapi";

const { Option } = Select;

const CurrentDrugs = ({ AIQueryData, handleChange }) => {
  console.log("AIQueryData",AIQueryData);
const [options, setOptions] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

const onFinishDrugs = (values) => {
  console.log("values:", values);

    handleChange("drugs", values);
  };
  const onValuesChange = (changedValues, allValues) => {
    console.log("Changed values:", changedValues);
    console.log("allValues:", allValues);
    const validIndexes = [];

    allValues.drugs.forEach((element, index) => {
      if (element !== undefined) {
        validIndexes.push(element);
        // Perform any additional operations you need with the non-undefined element
        console.log(`Index ${index}:`, element);
      }
    });
    
   
    handleChange("drugs", validIndexes);

  };
  const handleSearch = async (value) => {
    setSearchTerm(value);

    try {
      const response = await getFDADrugs(value);
      console.log(response.results);
      const drugOptions = response.results.map((drug) => ({
        value: drug.brand_name,
        label: drug.brand_name,
      }));
      setOptions(drugOptions);
    } catch (error) {
      console.error("Error fetching FDA drugs:", error);
      setOptions([]);
    }
  };

  const onSelect = (value, option) => {
    console.log(value);
  };



  useEffect(() => {
    // Clear options when the component unmounts
    return () => setOptions([]);
  }, []);

  return (
    <div dir="ltr">
      <div className="container">
        <Card
          
          type="inner"
          className="custom-card"
          title="Enter Drugs"
          style={{ width: "80%", backgroundColor: "#f0f0f0" }}
        >
                <Form
                  name="dynamic_form_nest_item"
                  autoComplete="off"
                  initialValues={AIQueryData}
                  onFinish={onFinishDrugs}
                  onValuesChange={onValuesChange}
                  initialValue={[{ drug: '', drugdoze: '', TimeDuration: '', count: '' }]}
                >
                  <Form.List 
                  name="drugs"
                 
                    >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField },index) => (
                          <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="center"
                            direction="horizontal"
                          >
                            <Grid container spacing={2}>

                             <Grid  xs={12} sm={4}>
                            <Form.Item
                              
                              {...restField}
                              name={[name, "drug"]}
                              rules={[{ required: true, message: "*" }]}
                            >
                              {/* <Input placeholder="نام دارو" /> */}
                              <AutoComplete
                                options={options}
                                onSelect={onSelect}
                                onSearch={handleSearch}
                                value={searchTerm}
                                
                              >
                                <Input.Search
                                  placeholder="Drugs Name     "
                                  enterButton
                                />
                              </AutoComplete>
                            </Form.Item>
                            </Grid>
                            <Grid  xs={12} sm={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "drugdoze"]}
                              rules={[{ required: true, message: "*" }]}
                            >
                              <Input maxLength={3} placeholder=" Drug Dos" />
                            </Form.Item>
                            </Grid>
                            <Grid  xs={12} sm={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "TimeDuration"]}
                              rules={[{ required: true, message: "*" }]}
                            >
                              <Input prefix={<ClockCircleOutlined />} placeholder="each time" />
                            </Form.Item>
                            </Grid>
                            <Grid  xs={12} sm={2}>
                            <Form.Item
                              {...restField}
                              name={[name, "count"]}
                              rules={[{ required: true, message: "*" }]}
                             
                            >
                              <Input maxLength={2}   placeholder="Count"   />
                            </Form.Item>
                            </Grid>
                            <Grid  xs={12} sm={1}>
                            <Form.Item>
                            { index !=0 && index === fields.length -1 && (
                             <Button
                                type="dashed"
                                onClick={() => remove(name)}
                                block
                                icon={<MinusOutlined />}
                              ></Button>
                            )}
                            
                            </Form.Item>
                            </Grid>
                            <Grid  xs={12} sm={1}>
                            <Form.Item
                               >
                             {index === fields.length - 1 &&( <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              ></Button>)}
                            </Form.Item>
                            </Grid>
                           </Grid>
                          </Space>
                        ))}
                      </>
                    )}
                  
                  </Form.List>
                  
                  <Form.Item>
                    {AIQueryData.drugs.map((item) =>
                      item.drug != "" ? <CheckOutlined key={item.drug} /> : ""
                    )}

                    {/* <Button type="primary" htmlType="submit">
                      ثبت موقت
                    </Button> */}
                  </Form.Item>
                </Form>
        </Card>
      </div>
    </div>
  );
};
export default CurrentDrugs;
