import React, { useState } from 'react';
import { Button, Flex, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const CopyToClipboardButton = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };

  return (
    <div>
      <Button icon={<CopyOutlined />} onClick={copyToClipboard}>
      </Button>
    </div>
  );
};

export default CopyToClipboardButton;
