import React, { useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';

const OAuthCallback = ({ authCode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dataasautcode = location.state?.data;
  useEffect(() => {
    console.log("authCode:",dataasautcode);
    if (dataasautcode) {
      // Exchange authCode for access token and redirect to a content page
        fetchToken(dataasautcode).then(() => {
        //navigate('/');
      });
    }
  }, [dataasautcode]);

  const fetchToken = async (code) => {
    // Exchange the code with Google for an access token
    const response = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        client_id: '542338692274-9en8i25bfut1fpjriauajql18ggtlfjc.apps.googleusercontent.com',
        client_secret: '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',
        redirect_uri: 'https://aitabib.online/oauthcallback',
        grant_type: 'authorization_code',
      }),
    });

    const data = await response.json();
    console.log('data: ', data);

    console.log('Access Token: ', data.access_token);
  };

  return <div>Loading...</div>;
};

export default OAuthCallback;
