import React, { useState, useEffect,useRef } from "react";

import { Button, Steps, theme, Modal,Checkbox,Label } from "antd";
import { colors } from "@mui/material";
import { blue } from "@mui/material/colors";
import CopyToClipboardButton  from "../../utils/bases/CopyToClipboardButton"
import { useNavigate } from 'react-router-dom';


const TermsOfUsed =({handleTermsOfUsedChange}) => {
  const [citydata, setCity] = useState();
  const [provincedata, setProvince] = useState();
  const navigate = useNavigate();

  const endOfPageRef = useRef();

  useEffect(() => {

  }, []);


  const handlechecked=(values)=>
  {
    handleTermsOfUsedChange("termsofused",values.target.checked);
    console.log(values.target.checked);
    endOfPageRef.current.scrollIntoView({ behavior: "smooth" });

  }
 
  return (
    <>
      <div className="termofusebody" dir="ltr">
        <div className="termofusemain">
          <div className="termsofservicelogo"></div>
  
          <h3></h3>
          <p>
            [AI Tabib] is a counseling tool that provides information about potential drug interactions based on artificial intelligence algorithms. By using this program, you confirm and agree to the following conditions:
          </p>
  
          <p>
            <strong>Nature of Counseling:</strong> Information provided by <span style={{ color: '#00f' }}> [AI Tabib] </span> is for informational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment. This is a counseling tool and should not be considered a substitute for the expertise and judgment of healthcare professionals.
          </p>
  
          <p>
            <strong>No Liability:</strong> The owner and operators of <span style={{ color: '#00f' }}> [AI Tabib] </span> (hereinafter referred to as the "Owner") do not assume any responsibility for the accuracy, completeness, or usefulness of the information provided. The Owner has no liability for direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use <span style={{ color: '#00f' }}> [AI Tabib] </span>.
          </p>
  
          <p>
            <strong>Not a Substitute for Professional Counseling:</strong> This <span style={{ color: '#00f' }}> [AI Tabib] </span> is not a substitute for professional medical advice, diagnosis, or treatment. Users are advised to consult with healthcare professionals for any concerns or medical decisions.
          </p>
  
          <p>
            <strong>User's Responsibility:</strong> Users are solely responsible for any decisions made based on the information provided by <span style={{ color: '#00f' }}> [AI Tabib] </span>. The Owner has no responsibility for the consequences of user decisions or actions.
          </p>
  
          <p>
            <strong>Physician-Patient Relationship:</strong> The use of <span style={{ color: '#00f' }}> [AI Tabib] </span> does not create a physician-patient relationship between the user and the Owner. No confidential or physician-patient relationship is established by using the program.
          </p>
  
          <p className="termofusefooter">
            By using <span style={{ color: '#0ff' }}> [AI Tabib] </span>, you acknowledge that you have read, understood, and agreed to the terms and conditions stated in this disclaimer.
          </p>
  
          <p dir="ltr">This service is currently free; you can donate if you wish.</p>
  
          <p dir="ltr">
            <strong>USDT:</strong> TEcaMdhd1oEhneUjgj8w2f6te6AJq8Jctc
            <CopyToClipboardButton textToCopy="TEcaMdhd1oEhneUjgj8w2f6te6AJq8Jctc" />
          </p>
  
          <div ref={endOfPageRef}>
            <label>
              <input type="checkbox" onChange={handlechecked} /> Agree to the terms
            </label>
          </div>
        </div>
      </div>
    </>
  );
  
}

export default TermsOfUsed;
