import{BASE_URL} from "../../config/index"
import Axios from "axios";

// const ENDPOINTS = {
//   getAllCitiesurl: "/Province/get",

// };


export const getFDADrugs = (brandname) => {
    return Axios.get(
      `https://api.fda.gov/drug/ndc.json?search=brand_name:${brandname}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
           Authorization: `Basic lI3nPfg5wFbA1q8iSYoikVhYI8N2A6yVfWsi5nO7`
        },
      }
    )
      .then((response) => {
        //console.log(response.data)
        return response.data;
      })
      .catch((error) => { });
  };
  // export const getAllProvinceSorted = (PageNumber, PageSize) => {
  //   return Axios.get(
  //     BASE_URL + "/Province/get",
  //     { PageSize: 1000, PageNumber: 1 },
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         // Authorization: `Bearer ${token}`
  //       },
  //     }
  //   )
  //     .then((response) => {
  //       //console.log(response.data)
  //       return response.data;
  //     })
  //     .catch((error) => { });
  // };
  // export const getProvinceById = (id) => {
  //   return Axios.get(
  //     BASE_URL + "/Province/getbyid",
  //     {id },
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         // Authorization: `Bearer ${token}`
  //       },
  //     }
  //   )
  //     .then((response) => {
  //       //console.log(response.data)
  //       return response.data;
  //     })
  //     .catch((error) => { });
  // };
  // export const getProvinceByName = (name) => {
  //   return Axios.get(
  //     BASE_URL + "/Province/getbyName",
  //     {name },
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         // Authorization: `Bearer ${token}`
  //       },
  //     }
  //   )
  //     .then((response) => {
  //      // console.log(response.data)
  //       return response.data;
  //     })
  //     .catch((error) => { });
  // };
  // export const AddProvince = (countryId,countryName) => {
  //   return Axios.post(
  //     BASE_URL + "/Province/save",
  //     {countryId,countryName },
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         // Authorization: `Bearer ${token}`
  //       },
  //     }
  //   )
  //     .then((response) => {
  //      // console.log(response.data)
  //       return response.data;
  //     })
  //     .catch((error) => { });
  // };

