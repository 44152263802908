import React, { useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  CardContent,
} from "@mui/material";

import "../../assets/css/cms.css";
import "antd/dist/reset.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Button, Form, Input, Space, Row, Select} from "antd";

import { CheckOutlined } from "@ant-design/icons";
import { Icon } from '@iconify/react';

import MyPDFUploader from "../MyPDFUploader";
const { Option } = Select;

const MedicalTests = ({ AIQueryData, handleChange }) => {
  console.log(AIQueryData);

  const [errorMessage, setErrorMessage] = useState('');
  const [ageValidationStatus, setAgeValidationStatus] = useState('');


  return (
    <div dir="ltr">
      <div className="container">
        <Card
          type="inner"
          title=" Your Medical tests Info as PDF file :"
          className="custom-card"
          style={{
            width: "80%",
            backgroundColor: "#f0f0f0",
          }}
        >
          <CardContent>
         
            {/* <Form> */}
            <Grid container spacing={2}>
            <p dir="ltr">
  <span>If you have a PDF report as  latest medical test with:</span>
  <ul>
    <li>Tumor Marker</li>
    <li>Immunology</li>
    <li>Serology</li>
    <li>Biochemistry</li>
    <li>Special Biochemistry</li>
    <li>Hormones</li>
    <li>Hematology</li>
  </ul>
  <span>items, upload it.</span>
</p>
            
              <Grid item xs={12} sm={4}>
                   <MyPDFUploader handleChange={handleChange} />          
              </Grid>

            </Grid>
       
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                {errorMessage}
              </Grid>
            </Grid>
            {/* </Form> */}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
export default MedicalTests;
