import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  CardContent,
} from "@mui/material";

import "../assets/css/cms.css";
import "antd/dist/reset.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Button, Form, Input, Space, Row, Select } from "antd";
import CopyToClipboardButton  from "../utils/bases/CopyToClipboardButton";
import { Icon } from '@iconify/react';

const Donation = ({ handleTermsOfUsedChange }) => {
  
  useEffect(() => {}, []);



  return (
    <div className="container">
      <div className="termofusebody" dir="ltr" style={{ marginTop: "100px" }}>
        <div className="termofusemain">
          <h3>Thank you for your visit</h3>
          <p>
            The purpose of developing this service is to focus on health issues with the development of artificial intelligence for the general public. Nowadays, as health has turned into a business, people need to take action with sufficient knowledge and information about their medical affairs. In this section, we try to expand people's knowledge with the minimum cost so that correct decisions can be made in critical situations.
          </p>
          <div className="aimedicaldonation"></div>
          <p>This service is currently free; you can donate if you wish.</p>
          <p dir="ltr">
            <strong>USDT:</strong> TEcaMdhd1oEhneUjgj8w2f6te6AJq8Jctc
            <CopyToClipboardButton textToCopy="TEcaMdhd1oEhneUjgj8w2f6te6AJq8Jctc" />
          </p>
        <a link="instagram" href="https://www.instagram.com/aitabib"><Icon icon="skill-icons:instagram"  width="30" height="30" /></a>  
        <a link="x" href="https://twitter.com/AITabib"> <Icon icon="devicon:twitter" width="30" height="30"/></a> 
        <a link="telegram" href=""> <Icon icon="logos:telegram" width="30" height="30"/></a> 
        </div>
      </div>
    </div>
  );
  
};

export default Donation;
