
import React from "react";
import { Route, Routes } from "react-router-dom";
import MedicalCriteriaConfig from '../views/MedicalCriteriaConfig.jsx';
import Donation from '../views/Donation.jsx';
import MedicalTests from '../views/MedicalCriteriaSteps/MedicalTests.jsx'
import MyPDFUploader from "../views/MyPDFUploader.tsx";
import OAuthCallback from "../views/MedicalCriteriaSteps/OAuthCallback.jsx";
import SignIn from "../views/MedicalCriteriaSteps/SignIn.jsx";

const MyRoutes=()=>{

  return(
  <Routes>
    
    <Route path="/" Component={MedicalCriteriaConfig} />
    <Route path="/donation" Component={Donation} />
    <Route path="/tests" Component={MedicalTests} />
     <Route path="/tests2" Component={MyPDFUploader} /> 
     <Route path="/OAuthCallback" Component={OAuthCallback} /> 
     <Route path="/SignIn" Component={SignIn} /> 



   
  </Routes>
  );

};

export default MyRoutes;
